import React, { useEffect, useState, memo } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';
import Cookies from 'universal-cookie';
import LeftSide from './LeftSide';
import Rightside from './Rightside'
import { useLocation } from "react-router-dom";

import {fixValue, objIsNotEmpty, useTvGlobalState} from './utils';

// react query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from '../serives/react-query/provider';
import { refreshBalanceApiRq, getGameBalanceApiRq, enterCasinoTransactionApiRq } from '../serives/react-query/api-actions';
import {RQ_KEY} from "../serives/react-query/constant";
import {getRqData, useRqDataWithInitialData, useRqRealTimeData} from "../serives/react-query/rq-helper";

import {createGlobalState } from  'react-use';

const cookies = new Cookies();





const Header = (props) => {

	const [openMainbalance, setopenMainbalance] = useState(false);
	const [refresh, setrefresh] = useState(false);
	const [isTransferFiald, setIsTransferFiald] = useState({ msg: '', isFaild: false });
	const [innerLoading, setInnerLoading] = useState(false);

	const [tvState, setTvState] = useTvGlobalState();

	const apiURLHandler = 'https://liveapi247.live/api8/';

	const location = useLocation();
	const isFullMarketPage = location.pathname.includes('fullmarket/');

	var GeCredit = props.GEbalance;
	var awc_credit = props.AEBalance;
	var kg_credit = props.REBalance;
	var ezugi_credit = props.EZBalance;
	let jili_credit = props.JLBalance

	const getBalanceApiCall = async (username, providercode) => {
		try {
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'getBalance',
				data: {
					username,
					password: 'aaaa1111',
					providercode
				}
			});
			if (response.data.status == true) {
				let value = response.data.data.balance;
				return value;
			}
			return false;
		} catch (error) {
			return false;
		}
	}
	const openMainBal = async (val) => {
		if (val == true) {
			handleLoader(true)
			const utoken = cookies.get('token');
			const user = cookies.get('id');

			// fetch specific balances
			let geBal = '0';
			let s2Bal = '0';
			let reBal = '0';
			let ezBal = '0';
			let jlBal = '0';
			let getGEBal = await getBalanceApiCall(user, 'GE');

			if (getGEBal !== false) {
				geBal = getGEBal;
			}
			if (String(geBal) !== String(props.GEbalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, geBal, 'ge_credit', utoken)
			}


			let getS2Bal = await getBalanceApiCall(user, 'S2');

			if (getS2Bal !== false) {
				s2Bal = getS2Bal;
			}

			if (String(s2Bal) !== String(props.AEBalance)) {
				// debugger
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, s2Bal, 'awc_credit', utoken)
			}

			let getREBal = await getBalanceApiCall(user, 'RE');

			if (getREBal !== false) {
				reBal = getREBal;
			}
			if (String(reBal) !== String(props.REBalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, reBal, 'kingmaker_credit', utoken)
			}

			let getJLBal = await getBalanceApiCall(user, 'JL');

			if (getJLBal !== false) {
				jlBal = getJLBal;
			}
			if (String(jlBal) !== String(props.JLBalance)) {
				// update Balance trigger
				doUpdateBalance(user, props.mainBalance, jlBal, 'jili_credit', utoken)
			}

			// let getEZBal =await getBalanceApiCall(user, 'EZ');

			// if (getEZBal !== false) {
			// 	ezBal = getEZBal;
			// }
			// if(String(ezBal) !== String(props.EZBalance)) {
			// 	// update Balance trigger
			// 	doUpdateBalance(user, props.mainBalance, ezBal, 'ezugi_credit', utoken)
			// }
		}
		props.setupdated(!props.updated);
		setopenMainbalance(val)
		handleLoader(false)
		// handleRefresh();
	}

	const handleLoader = (val) => {
		setrefresh(val);
	}
	/* Update Balance api from vendor side start */


	const doUpdateBalance = async (username, mainBalance, amountToBeUpdate, casinoType, token) => {
		try {
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'update_balance',
				data: {
					id: username,
					LimitCurr: mainBalance,
					casino_value: amountToBeUpdate,
					casino_type: casinoType,
					token
				}
			});
			if (response.data) {
				// console.log('Balance updated successfully!');
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	}
	/* Update Balance api from vendor side end */

	/* Get Balance status from vendor side api start */
	const getBalance = async (username, password, providercode, mainBalance, token, refreshBalanceGameAmount) => {
		try {
			// debugger
			let response = await axios({
				method: 'POST',
				url: apiURLHandler + 'getBalance',
				data: {
					username,
					password,
					providercode
				}
			});
			if (response.data.status == true) {
				let value = response.data.data.balance;
				if (providercode === "GE") {
					let casinoTypeCode = 'ge_credit';
					//   localStorage.setItem('ge_credit', value);
					props.setGEbalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '')
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "S2") {
					let casinoTypeCode = 'awc_credit';
					//   localStorage.setItem('awc_credit', value);
					props.setAEBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "RE") {
					let casinoTypeCode = 'kingmaker_credit';
					// localStorage.setItem('kingmaker_credit', value);
					props.setREBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "JL") {
					let casinoTypeCode = 'jili_credit';
					// localStorage.setItem('kingmaker_credit', value);
					props.setJLBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}

				if (providercode === "EZ") {
					let casinoTypeCode = 'ezugi_credit';
					// localStorage.setItem('ezugi_credit', value);
					props.setEZBalance(value)
					localStorage.setItem('transactionState', false);
					localStorage.setItem('transactionCode', '');
					// update balance from vendor api
					// Check if both api balance is same
					// Do update only if not same
					if (String(value) !== String(refreshBalanceGameAmount)) {
						doUpdateBalance(username, mainBalance, value, casinoTypeCode, token)
					}
				}
			}
			return true;
		} catch (error) {
			return false;
		}
	}

	// refresh balance rq
	const {data: getBlanceStatus} = useRqDataWithInitialData(RQ_KEY.STORE_USER_DATA, { });


	// console.log('getBlanceStatus',getBlanceStatus)
	// const getBlanceStatus =  getRqData(RQ_KEY.STORE_USER_DATA);
	// const { data, status } = getBlanceStatus ? getBlanceStatus : {};
	//
	// const pendingBalance = data?.pendingBalance;

	const utoken = cookies.get('token');
	const user = cookies.get('id');

	const refreshBalanceApiAction = useMutation({
		mutationFn: refreshBalanceApiRq,
		onMutate: () => {
			props.setloading(true);
		},
		onSuccess: rsp => {
			queryClient.setQueryData([RQ_KEY.STORE_USER_DATA], rsp);
			setTimeout(() => {
				setInnerLoading(false)
			}, 1000)
			props.setloading(false);
		},
		onError: () => {
			props.setloading(false);
			setInnerLoading(false)
		}
	});

	const handleRefresh = () => {
		refreshBalanceApiAction.mutate({ id: user, token: utoken });
	}
	useEffect(() => {
		if (props.isLoggedIn === true) {
			handleRefresh();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, props.isLoggedIn]);



	// if blance is from the provider then get blance from provider

	// const enterCasinoTransactionApiAction = useMutation({
	// 	mutationFn: enterCasinoTransactionApiRq,
	// 	onSuccess: rsp => {
	// 		handleRefresh();
	// 		if (rsp.data.status === false) {
	// 			setIsTransferFiald({ msg: rsp.data.message, isFaild: true });
	// 		}
	// 	}
	// });

	// const getGameBalanceApiAction = useMutation({
	// 	mutationFn: getGameBalanceApiRq,
	// 	onSuccess: ({ data, status }) => {
	// 		const gameBalance = data.data.balance;
	// 		const { providercode, member } = pendingBalance;
	//
	// 		if (data && status == 200 && gameBalance > 0) {
	// 			// transfer ammount to ac
	// 			enterCasinoTransactionApiAction.mutate({
	// 				username: member,
	// 				transferType: 'w',
	// 				providercode,
	// 				amount: gameBalance,
	// 				token: utoken,
	// 			})
	// 		}
	//
	// 	}
	// });


	useEffect(() => {
		if (getBlanceStatus && objIsNotEmpty(getBlanceStatus)) {
			const { data, status } = getBlanceStatus;

			// if (status === 210 && pendingBalance && pendingBalance?.type == 0) {
			//
			// 	const { providercode, member } = pendingBalance;
			// 	if (isTransferFiald.isFaild === false) {
			// 		getGameBalanceApiAction.mutate({
			// 			providercode,
			// 			username: member
			// 		})
			// 	}
			// }

			// set blance to state
			if (status === 210) {
				// props.setLoggedIn(true);
				const mainBalance = data.mainBalance - Math.abs(data.exposureBalance);

				if (mainBalance) {
					props.changeBalance(mainBalance);
					// mainBalance
					queryClient.setQueryData(['mainBalance'], fixValue(data.mainBalance, 'number'));
				}
				if (data.exposureBalance || data.exposureBalance === 0) {
					props.changeExposure(Math.abs(data.exposureBalance));
				}

			} else {
				setTimeout(() => {
					if (data === 'Logged in from another browser' || status !== 210) {
						console.log('hit log out')
						props.handleLogout();
					}
				}, 60000)
				// props.setLoggedIn(false);
			}

			// logout other device login
			if (data === 'Logged in from another browser') {
				console.log('hit log out 2')
				props.handleLogout();
			}
		}

	}, [getBlanceStatus]);


	// loading state
	// useEffect(() => {
	// 	if (getGameBalanceApiAction.isLoading || enterCasinoTransactionApiAction.isLoading || refreshBalanceApiAction.isLoading) {
	// 		props.setloading(true);
	// 	} else {
	// 		props.setloading(false);
	// 	}
	// }, [refreshBalanceApiAction, enterCasinoTransactionApiAction, getGameBalanceApiAction])

	return (
		<React.Fragment >
			{props.openLeftside === true &&
				<LeftSide userid={props.userid} utoken={props.utoken} handleleftsideclose={props.handleleftsideclose} />
			}
			{props.openRightside === true &&
				<Rightside handlerightsideclose={props.handlerightsideclose} userid={props.userid} utoken={props.utoken} />
			}
			{props.refreshhh === true &&
				<div className="loading-overlay" id="loading">
					<div className="loading-wrap" style={{ display: " flex" }}>
						<div className="loading">
							<div></div>
							<div></div>
						</div>
						<p>{props.refreshMsg}</p>
					</div>
				</div>}

			{
				refresh == true ?
					<div className="loading-overlay" id="loading">
						<div className="loading-wrap" style={{ display: " flex" }}>
							<div className="loading">
								<div></div>
								<div></div>
							</div>
							<p>Loading...</p>
						</div>
					</div>
					:
					null
			}

			<header>
				{props.isLoggedIn === false &&
					<h1 className="mob-header-logo"></h1>}
				{props.isLoggedIn === false &&
					<a class="btn-signup ui-link" onClick={()=> props.setShowRegister(true)} >Register</a>

				}
				{props.isLoggedIn === false &&
					<a className="mob-login-index ui-link" onClick={() => { props.checkShowLogin(true); }}>Login</a>
				}



				{props.isLoggedIn === true &&
					<ul>
						<li className="live-tv-icon">
							{/*{props.tabMenu === 0 && props.liveStream && props.liveStream != '' && <a id="openTV" onClick={() => { props.ChangeTv(!props.Tvopen) }} className={` ${props.Tvopen ? "a-open_tv ui-link close_tv" : "a-open_tv ui-link"}`}   >*/}
							{/*	<img src={Transparent} /></a>}*/}

							{(tvState && tvState.showTvCloseBtn && isFullMarketPage &&  props.isLoggedIn) && <a onClick={() => {
								setTvState({
									...tvState,
									isTvShow: !tvState.isTvShow
								})
							}} className={`${tvState.isTvShow ? "a-open_tv ui-link close_tv" : "a-open_tv ui-link"}`}>
								<img src={Transparent} /></a>}

							<a id="openBetsBtn" className="mob-bet-show ui-link" onClick={props.handleleftside}  >
								<img src={Transparent} />Bets</a>
						</li>

						<li className="main-wallet">
							{/*/<!-- open-->*/}

							<a id="multiWallet" className="a-wallet ui-link">
								{innerLoading === false &&
									<ul id="accountCredit" >
										<li   ><span>Main</span> <span id="betCredit">USD {props.balance.toFixed(2)}</span></li>
										<li  ><span>Exposure</span> <span id="totalExposure" className={`${props.exposure > 0 ? "red" : ""}`}>{props.exposure > 0 ? '(' + parseFloat(props.exposure).toFixed(2) + ')' : props.exposure.toFixed(2)}</span></li>
										<li className="nums">+<span id="vendorQuantity">1</span> </li>
									</ul>}

								{innerLoading &&
									<p className="loading-bar" id="menuRefreshLoading" >
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
									</p>}
							</a>
							<a className="a-refresh ui-link" id="menuRefresh" onClick={() => { handleRefresh(); setInnerLoading(true); }} title="Refresh Main Wallet"><img src={Transparent} /></a>
						</li>
						<li  ><a className="a-setting ui-link" onClick={() => { props.handlerightside() }} title="Setting"><img src={Transparent} /></a></li>
					</ul>
				}

				{/* casino recall to main balance */}

				{props.msgNumber === 1 && <div id="msgBox" className="message-content success to-open_bets">
					<div className="message">
						<h4 id="header">Bet Matched</h4>
						<p id="info">
							<span id="sideType" className="lay">{props.betInfo.oddstype}</span>
							<strong id="selectionName">{props.betInfo.runner}</strong> <strong id="stake">{props.betInfo.amount}</strong> at odds <strong id="odds">{props.betInfo.rate}</strong>
						</p>
					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}



				{props.msgNumber === 2 && <div id="msgBox" className="message-content warning to-open_bets" >
					<div className="message">
						<h4 id="header">The stake you have entered are below the minimum.</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}


				{/*error messages */}
				{props.msgNumber === 3 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Bet Blocked</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 4 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Bet Closed</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 5 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Insufficient Coin</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 6 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Odds Changed</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 7 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Bet can not be placed!</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}
				{props.msgNumber === 8 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Enter valid coin value!</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 9 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Bet Over 75 not accepted!</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 10 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Duplicate Bet Not allowed!</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}
				{props.msgNumber === 11 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Min bet is {props.stakeInfo.minStake}</h4>
					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 12 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Max bet is {props.stakeInfo.maxStake}</h4>

					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 404 && <div id="msgBox" className="message-content error to-open_bets">
					<div className="message">
						<h4 id="header">Unknown error</h4>
					</div>
					<a id="close" className="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

			</header>

		</React.Fragment>
	)
}

export default memo(Header);
