export const amountRegex = new RegExp(
    /^[0-9]\d{0,9}(\.\d{1,3})?%?$/
);

export const onlyNumberRegex = new RegExp(
    /^[0-9]/
);

export const phoneRegex = new RegExp(
    /^[89][0-9]{9}/
);

export const strongPassRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/)


export const userNameRegex = new RegExp(/^([a-zA-Z0-9]{6,15})$/)
