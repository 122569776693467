import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import axios from 'axios';
import {useBannerData} from "../serives/react-query/api-hooks";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";


const photos = []

const settings = {
    dots: false,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    className: 'slidesN'
}


export default function Carousel() {
    const [getBanners, setBanners] = useState([])


    // get Banner data
    let getBannerData = getRqData(RQ_KEY.STORE_BANNER_DATA);

    // enabled is on mount data get
    const { data } = useBannerData({
        // enabled: !getBannerData,
        select: (data) => data.data
    });


    useEffect(() => {
        // async function fetchData() {
        let val = getBannerData?.data;
        if (val && val.length !== 0) {
            let newResp = [{ url: val[0].banner1 }, { url: val[0].banner2 }, { url: val[0].banner3 }, { url: val[0].banner4 }, { url: val[0].banner5 }, { url: val[0].banner6 }, { url: val[0].banner7 }, { url: val[0].banner8 }, { url: val[0].banner9 }, { url: val[0].banner10 }]
            setBanners(newResp)
        }
        // }
        // fetchData()
    }, [getBannerData]);

    return (
        <React.Fragment>
            <Slider
                {...settings}
                style={{ height: '32vw' }}

            >
                {getBanners.filter((x, i) => x.url !== null).map((photo, id) => {
                    return (
                        <div key={id}>
                            <img src={photo.url} alt="cric" style={{ height: '32vw', width: '100vw' }} />
                        </div>
                    )
                })}
            </Slider>
        </React.Fragment>
    )
}
