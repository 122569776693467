import React from 'react';
import queryString from "query-string";
import {getCasinoObj} from "../../../casino-utils";

const CasinoCard = ({ title, navKey, img, isLoggedIn, history, openLogin, className, style}) => {

    const newStyle = {
        borderRadius: '5px'
    }
    return (
        <a
            className={className}
            onClick={() => navKey ? isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj(navKey))}`) : openLogin() : null}
            style={style ? {...style, ...newStyle} : {gridColumn: 'span 2',...newStyle}}>
            <dl className="casino-title" style={{ background: 'linear-gradient(0deg,#000,transparent)', padding: '4px'}}>
                <dt style={{ color: 'white', fontWeight: 'normal'}}>{title}</dt>
            </dl>

            <img src={require('../../../images/casino/'+img)} alt={title} draggable="false"/>
        </a>
    )
}

export default CasinoCard;
