import React, {useEffect, useState} from 'react';
import SelectBank from "./components/select-bank";
import {fixValue, objIsNotEmpty} from "./utils";
import { FaCopy } from "react-icons/fa6";
import {useCopyToClipboard} from "react-use";
import axios from "axios";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
import {onlyNumberRegex} from "./reg-ex";
const cookies = new Cookies();

toast.configure();

const withdrawAmount = async (payload) => await axios.post('https://liveapi247.live/apiv9/withdraw', payload)

const InfoInput  = ({ title, defaultValue, value, copy, ...rest}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [state, copyToClipboard] = useCopyToClipboard();

    const onClickCopy = ()=> {
        setShowTooltip(true);
        copyToClipboard(value || defaultValue);
        setTimeout(()=> setShowTooltip(false), 1500);
    }

    return (
        <>
            {title && (
                <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                    <span>{title} </span>
                    {copy && (
                        <div style={{ position: 'relative', display: 'inline-block'}}>
                            <FaCopy size="1.2rem" style={{ cursor: 'pointer'}} onClick={onClickCopy} />
                            {showTooltip && <span style={{
                                "padding": "2px 9px",
                                "backgroundColor": "#000000df",
                                "borderRadius": "4px",
                                "color": "#fff"
                            }}>copied!</span>}

                        </div>
                    )}

                </div>
            )}
            <div style={{display: 'flex'}}>
                <input
                    type="text"
                    className="text-input"
                    style={{
                        "border": "1px solid #00000030",
                        "borderRadius": "6px",
                        "marginBottom": "6px",
                        "padding": "8px",
                        "width": "100%"
                    }}
                    value={value}
                    defaultValue={defaultValue}
                    {...rest}
                />
            </div>
        </>
    )
}


const Withdraw = () => {

    const defaultFormData = {
        paymentMethod: '',
        amount: '',
        mobile: '',
        bankAccountNum: '',
        bankAccountName: 'Personal',
        currPaymentMethod: {},
    }

    const [formData, setFormData] = useState(defaultFormData);
    const setFromDataFn = (key, value) => setFormData({
        ...formData,
        [key]: value
    })
    const setMultiFromDataFn = (data) => setFormData({
        ...formData,
        ...data
    })


    const onSubmit = async ()=> {
        let token = cookies.get('token');
        let clientId = cookies.get('id');
        if(clientId){
            clientId = clientId.toUpperCase()
        }

        if(!formData.paymentMethod || formData.paymentMethod === '') {
            alert('Please select Withdraw Type.');
        } else if(!formData.bankAccountNum || formData.bankAccountNum === '') {
            alert('Please Enter Bank Account Number.');
        }  else if(!formData.amount || !(formData.amount >= 500)){
            alert('Can not Withdraw Less than 500');
        } else if (!formData.mobile || formData.mobile === '' || !onlyNumberRegex.test(formData.mobile) || formData.mobile.length < 10){
            alert('The Phone Number must have 10 number');
        } else {
            // success

            await withdrawAmount({
                mobileNumber: formData.mobile,
                accountNumber: formData.bankAccountNum,
                amount: fixValue(formData.amount, 'number'),
                paymentMethod: formData.paymentMethod,
                clientId,
                token
            }).then(({data, status})=> {
                if(data && data.status){
                    setFormData(defaultFormData)
                    toast.success( data.msg ?  data.msg : 'Your Amount is Withdraw Request Successfully.', { position: toast.POSITION.TOP_CENTER })
                } else {
                    toast.error(data.msg, { position: toast.POSITION.TOP_CENTER })
                }
            })


        }
    }


    return (
        <>
            <div style={{"alignItems": "center", "borderBottom": "2px solid #ff00a9", "padding": "10px 5px"}}>
                <div style={{"color": "#ff00a9", "fontSize": "1.3rem", "fontWeight": "700", "textAlign": "center"}}>Withdraw Transaction</div>
            </div>

            <div style={{"marginBottom":"35px","overflowY":"auto","padding":"13px"}}>
                <div style={{
                    "border": "1px solid #00000030",
                    "borderRadius": "6px",
                    "marginBottom": "16px",
                    "padding": "8px"
                }}>

                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Withdraw Type</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SelectBank
                            paymentType="w"
                            isSelected={formData?.currPaymentMethod?.id}
                            onChange={i => setMultiFromDataFn({
                                paymentMethod: i?.id,
                                currPaymentMethod: i,
                            })}
                        />
                    </div>
                    {formData.paymentMethod && (
                        <>
                            <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                                <span>Bank Account Number</span>
                                <span style={{color: '#ff00a9'}}>*</span>
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    type="text"
                                    className="text-input"
                                    style={{
                                        "border": "1px solid #00000030",
                                        "borderRadius": "6px",
                                        "marginBottom": "6px",
                                        "padding": "8px",
                                        "width": "100%"
                                    }}
                                    value={formData?.bankAccountNum}
                                    onChange={(e) => setFromDataFn('bankAccountNum', e.target.value)}
                                />
                            </div>
                            <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                                <span>Bank Account Name</span>
                                <span style={{color: '#ff00a9'}}>*</span>
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    type="text"
                                    className="text-input"
                                    style={{
                                        "border": "1px solid #00000030",
                                        "borderRadius": "6px",
                                        "marginBottom": "6px",
                                        "padding": "8px",
                                        "width": "100%"
                                    }}
                                    value={formData?.bankAccountName}
                                    disabled={true}
                                />
                            </div>
                        </>
                    )}

                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Amount</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <input
                            name="amount"
                            type="number"
                            className="text-input"
                            id="depositAmt"
                            placeholder="0.00"
                            style={{
                                "border": "1px solid #00000030",
                                "borderRadius": "6px",
                                "marginBottom": "6px",
                                "padding": "8px",
                                "width": "100%"
                            }}
                            value={formData?.amount}
                            onChange={(e) => setFromDataFn('amount', e.target.value)}
                        />
                        <span style={{color: '#ff00a9', fontSize: 'medium'}}>* Min 500 Max 25000</span>
                    </div>


                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Phone Number</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex'}}>
                        <input
                            type="text"
                            className="text-input"
                            style={{
                                "border": "1px solid #00000030",
                                "borderRadius": "6px",
                                "marginBottom": "6px",
                                "padding": "8px",
                                "width": "100%"
                            }}
                            value={formData?.mobile}
                            onChange={(e) => setFromDataFn('mobile', e.target.value)}
                        />
                    </div>


                    <button type="button" onClick={onSubmit} style={{
                        "backgroundColor": "#ff00a9",
                        "border": "1px solid #ff00a9",
                        "borderRadius": "6px",
                        "color": "#fff",
                        "fontWeight": "700",
                        "marginBottom": "6px",
                        "marginTop": "10px",
                        "padding": "8px",
                        "textAlign": "center",
                        "width": "100%"
                    }}>Submit
                    </button>

                    <div className="deposit-description">
                        <div>
                            <span>Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by Cheque or Bank Draft (Company OR Personal Cheque) as your deposit method.</span>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span>Once you have successfully submitted your deposit form and once your funds is cleared in our account, just leave it to our team to process your transactions as speedy as possible. If more than 10 minutes, let us know by contacting our Customer Service support. They will assist you 24/7 anytime.</span>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span>Please make sure that you fill up accurate bank account details to avoid any inconveniences &amp;amp; using third-party withdrawal is NOT accepted by us.</span>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}
export default Withdraw;