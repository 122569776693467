import React, { useEffect, useState } from 'react'
import Cup from '../images/sprite/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import moment from 'moment'
import Kabaddi from './Kabaddi';
import Support from './Support';
import Binary from './Binary';
import Transparent from '../images/sprite/transparent.gif'
import { Link } from 'react-router-dom';
// import horse from '../images/horsel.6a5281de.svg'
// import MT5 from '../images/mt5.4f09b4e8.svg'

import Cookies from 'universal-cookie';
import BasketballMatches from './BasketballMatches';
import Election from './Election';
import {
	homeGetSportsCount,
	inPlayDataFilter,
	sportsDataFilter,
	sportsEventDataFilterCompetionName
} from '../utils/sportsHelper';
import {sportsKeyByName } from '../utils/constant';
import {getRqData} from "../serives/react-query/rq-helper";
import {useSportsCountData, useSportsData} from "../serives/react-query/api-hooks";
import {RQ_KEY} from "../serives/react-query/constant";
import {useGlobalLoading} from "../serives/state/states";
import CompetionTab from "./components/competion-tab/competion-tab";
import {getAllEventsApiRq, getSportsDataApiRq} from "../serives/react-query/api-actions";
import {onlyUniqueItem} from "./utils";
const cookies = new Cookies();

require('moment-timezone');

export default function Highlights(props) {
	const [loading, setLoading] = useGlobalLoading();
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	// const [countCricket, setCountCricket] = useState(0);
	// const [countSoccer, setCountSoccer] = useState(0);
	// const [CountBasketball, setCountBasketball] = useState(0);
	// const [countTennis, setCountTennis] = useState(0);

	// const [newrefresh, setnewrefresh] = useState(true);
	const [casinoWait, setcasinoWait] = useState(false);


	// const getAllSportsData = getRqData(RQ_KEY.STORE_SPORTS_DATA);
	// const getSportsData = getAllSportsData?.data || {};

	// const { data } = useSportsData({
	// 	enabled: !getAllSportsData,
	// 	onMutate: () => setLoading(true),
	// 	onSuccess: () => setLoading(false),
	// 	onError: (e) => {
	// 		// console.log('e', e)
	// 		setLoading(false);
	// 	}
	// });

	// get Banner data
	let getSportsCountData = getRqData(RQ_KEY.STORE_SPORTS_COUNT_DATA);

	// enabled is on mount data get
	const sportCount = useSportsCountData({
		// enabled: !getBannerData,
		select: (data) => data.data
	});
	let count = {};
	if(getSportsCountData && getSportsCountData.data && getSportsCountData.data.inPlay){
		count = getSportsCountData.data.inPlay;
	}

	// useEffect(() => {
	// 	if (getSportsData) {
	// 		const getCricketCount = homeGetSportsCount(sportsDataFilter(getSportsData[sportsKeyByName.CRICKET]));
	// 		setCountCricket(getCricketCount);
	// 		const getSoccerCount = homeGetSportsCount(sportsDataFilter(getSportsData[sportsKeyByName.SOCCER]));
	// 		setCountSoccer(getSoccerCount);
	// 		const getTennisCount = homeGetSportsCount(sportsDataFilter(getSportsData[sportsKeyByName.TENNIS]));
	// 		setCountTennis(getTennisCount);
	// 		const getBasketBallCount = homeGetSportsCount(sportsDataFilter(getSportsData[sportsKeyByName.BASKET_BALL]));
	// 		setCountBasketball(getBasketBallCount);
	// 	}
	// }, [getSportsData]);


	// function changecount(cri, socc, tenn, BasBall) {
	//
	// 	setCountCricket(cri);
	// 	setCountSoccer(socc);
	// 	setCountTennis(tenn);
	// 	setCountBasketball(BasBall);
	// }

	// useEffect(() => {
	// 	axios.all([
	// 		axios.get('https://liveapi247.live/api8/cricketData'),
	// 		axios.get('https://liveapi247.live/api8/soccerData'),
	// 		axios.get('https://liveapi247.live/api8/tennisData'),
	// 		axios.get('https://liveapi247.live/api8/BasketballData')
	// 	])
	// 		.then(axios.spread((obj1, obj2, obj3, obj4) => {
	//
	// 			setnewrefresh(false)
	// 			var count1 = 0;
	// 			var count2 = 0;
	// 			var count3 = 0;
	// 			var count4 = 0;
	//
	// 			obj1.data.map(item => {
	// 				item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
	// 				return item;
	// 			})
	//
	// 			obj2.data.map(item => {
	// 				item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
	// 				return item;
	// 			})
	// 			obj3.data.map(item => {
	// 				item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
	// 				return item;
	// 			})
	//
	// 			obj4.data.map(item => {
	// 				item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
	// 				return item;
	// 			})
	//
	// 			obj1.data.map((item) => {
	// 				if (moment() >= moment(item.marketStartTime)) {
	// 					count1 = count1 + 1
	// 				}
	// 			});
	// 			obj2.data.map((item) => {
	// 				if (moment() >= moment(item.marketStartTime)) {
	// 					count2 = count2 + 1;
	// 				}
	// 			});
	// 			obj3.data.map((item) => {
	// 				if (moment() >= moment(item.marketStartTime)) {
	// 					count3 = count3 + 1;
	// 				}
	// 			});
	//
	// 			obj4.data.map((item) => {
	// 				if (moment() >= moment(item.marketStartTime)) {
	// 					count4 = count4 + 1;
	// 				}
	// 			});
	//
	//
	// 			changecount(count1, count2, count3, count4);
	//
	//
	//
	// 		}));
	// }, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}


	const openAESexy = (gameI) => {
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
		else {
			var utoken = cookies.get('token');
			var user = cookies.get('id');
			var mil = Date.now();

			if (!user || !utoken) {
				return;
			}
			setLoading(true);
			axios.post('https://liveapi247.live/api/gapcasino/game/url', {
				user: user,
				token: utoken,
				platform: 'GPL_DESKTOP',
				lobby_url: window.location.href,
				game_id: gameI

			})
				.then(result => {

					setTimeout(() => { setLoading(false); }, 4000);
					if (result.data.url) {
						// window.open(result.data.url);
						//var newTab=window.open('about:blank');
						window.location.href = result.data.url;

					}


				})
				.catch(e => {

				});

		}
	}


	const [events, setEvents] = useState({
		1: [],
		2: [],
		4: []
	});
	useEffect(()=> {
		getAllEventsApiRq().then(events=> {
			// console.log('events',events)
			if(events){
				setEvents({
					[sportsKeyByName.CRICKET]: [...inPlayDataFilter(events[sportsKeyByName.CRICKET]), ...events[sportsKeyByName.CRICKET]].filter(onlyUniqueItem),
					[sportsKeyByName.SOCCER]: [...inPlayDataFilter(events[sportsKeyByName.SOCCER]), ...events[sportsKeyByName.SOCCER]].filter(onlyUniqueItem),
					[sportsKeyByName.TENNIS]: [...inPlayDataFilter(events[sportsKeyByName.TENNIS]), ...events[sportsKeyByName.TENNIS]].filter(onlyUniqueItem),
					[sportsKeyByName.BASKET_BALL]: [],
				})
			}
		})
	},[])


	return (

		<React.Fragment>
		
			{!loading &&
				<div  className='mob-main-content'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					<div  id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div  id="frame" className="mob-nav-highlight ps ps--theme_default ps--active-x">


							<ul  id="label">
								{props.isLoggedIn &&
									<li  className="menu-casino">
										<span  className="tag-new">New</span>
										<Link to="/CasinoList" id="casinoLoginBtn" ><img className="icon-casino" src={Transparent} />Casino</Link>
									</li>}

							
								<li  id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{count?.cricket}</span>
									<a  ><img  className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

						
								<li  id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{count?.soccer}</span>
									<a  ><img  className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>
								

								<li  id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{count?.tennis}</span>
									<a  ><img  className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>

							

							
							</ul>

							<div  className="ps__scrollbar-x-rail"><div  className="ps__scrollbar-x"  ></div></div><div  className="ps__scrollbar-y-rail" ><div  className="ps__scrollbar-y"  ></div></div></div>
					</div>

					<div  className="mob-nav-highlight_list" style={{ height: 'auto', backgroundColor: 'pink' }}  >
						<h3>  Highlights</h3>

						<ul  id="highlightList"
							 className="mob-sports-highlight-list"
						>
							{
								(events) && (
									<CompetionTab>
										{isByCompetion => (
											<>
												{highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} data={events[sportsKeyByName.CRICKET]} isByCompetion={isByCompetion} />}
												{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} data={events[sportsKeyByName.SOCCER]} isByCompetion={isByCompetion} />}
												{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}  data={events[sportsKeyByName.TENNIS]} isByCompetion={isByCompetion} />}

												{/*{highlightTab === 7522 && <BasketballMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} data={events[sportsKeyByName.BASKET_BALL]} />}*/}
												{highlightTab === 2378962 && <Election isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}/>}
												{highlightTab === 138 && <Kabaddi isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}/>}
											</>
										)}
									</CompetionTab>
								)
							}

						</ul>
					</div>
					{/* <Support handlepolicy={props.handlepolicy} /> */}

				</div>}
		</React.Fragment>
	)
}
