import React, {useEffect, useState} from 'react';
import axios from "axios";
import check from '../../images/other/select-check.svg';
import Cookies from 'universal-cookie';
import {paymentMethodKeys} from "../utils";
const cookies = new Cookies();

const getPaymentMethods = async (type, payload) => await axios.post(type === 'd' ? 'https://liveapi247.live/apiv9/deposit/getpaymentmethods' : 'https://liveapi247.live/apiv9/withdraw/getpaymentmethods', payload)


const paymentImgByPaymentKey = {
    [paymentMethodKeys.LOCAL_BANK]: require('../../images/payment/bank-card.png'),
    [paymentMethodKeys.PHONE_PAY]: require('../../images/payment/phonepe.png'),
    [paymentMethodKeys.GOOGLE_PAY]: require('../../images/payment/gpay.png'),
    [paymentMethodKeys.PAYTM]: require('../../images/payment/paytm.png'),
    [paymentMethodKeys.CRYPTO]: require('../../images/payment/cryptoswitch.png'),
    [paymentMethodKeys.NAGAD_AGENT]: require('../../images/payment/nagad.png'),
    [paymentMethodKeys.NAGAD_PERSONAL]: require('../../images/payment/nagad.png'),
    [paymentMethodKeys.BKASH_AGENT]: require('../../images/payment/bkash.png'),
    [paymentMethodKeys.BKASH_PERSONAL]: require('../../images/payment/bkash.png'),
    [paymentMethodKeys.ROCKET_AGENT]: require('../../images/payment/deposit-ewallet-rocket.svg'),
    [paymentMethodKeys.ROCKET_PERSONAL]: require('../../images/payment/deposit-ewallet-rocket.svg'),
}

const SelectBank = ({paymentType = 'd', isSelected, onChange}) => {
    const [paymentMethods, setPaymentMethods] = useState([]);


    useEffect(() => {
        let token = cookies.get('token');
        let clientId = cookies.get('id');

        getPaymentMethods(paymentType, { clientId, token}).then(({data, status})=> {
            if(status === 200){
                setPaymentMethods(data?.data)
            }
        })

    }, []);

    if(!paymentMethods || paymentMethods.length === 0) {
        return <h4 style={{color: '#ff00a9'}}>Payment Methods not found.</h4>
    }
    return (
        <div style={{
            "gridGap": "10px",
            "display": "grid",
            "flexWrap": "wrap",
            "gridTemplateColumns": "repeat(auto-fill, calc(33.33333% - 6.66667px))",
            "margin": 0,
            "padding": "12px",
            "width": "100%"
        }}>
            {paymentMethods.map((item, index)=> {
                const isChecked = item.id === isSelected;

                return (
                    <button
                        key={item.id + index}
                        style={{border: 0, background: 'black', borderRadius: '.8vw', padding: '0.3rem', position: 'relative'}}
                        onClick={() => onChange(item)}
                    >
                        <img src={paymentImgByPaymentKey[item.id]} alt={item.displayName} style={{width: "3rem"}}/>
                        <h6 style={{color: isChecked ? '#ffdf1a' : 'white', fontSize: '3vw', fontWeight: 'normal'}}>{item.displayName}</h6>
                        {isChecked && <img src={check} style={{ width: '5.3333333333vw',  position: "absolute", bottom: 0, right: 0,}}/>}
                    </button>
                )
            })}


        </div>
    )
}
export default SelectBank;